const config = {
  auth0ClientKey: "T9BnkCZJldSgqm6MM7DWhBp5TXRIpZgK",
  auth0Domain: "icc-vault.us.auth0.com",
  buildType: "whitelabel",
  buildName: "ICC",
  disableTennetBuildFts: false,
  writekey_segment: "",
  auth0SupportUrl: "mailto:support@coincustodian.id",
  apUrl: "https://admin-vault.coincustodian.id",
};

function overloadedConfig(key) {
  return config[key];
}
